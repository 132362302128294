.btnAssReview {
  background-color: #eeebe1;
  color: #aa9c68;
  width: 100% !important;
  max-width: fit-content;
  font-size: 12px;
  margin-left: 2%;
}

.btnAssReview:hover {
  background-color: #eeebe1;
  color: #aa9c68;
  width: 100% !important;
  max-width: fit-content;
  margin-left: 2%;
  font-size: 12px;
}

/* English Language Button */
.btnAssReviewE {
  background-color: black;
  color: white;
  width: 100% !important;
  max-width: fit-content;
  margin-left: 2%;
  font-size: 12px;
}

.btnAssReviewE:hover {
  background-color: black;
  color: white;
  width: 100% !important;
  max-width: fit-content;
  margin-left: 2%;
  font-size: 12px;
}

.pastPaperBox_heading {
  width: 100%;
}

.pastPaperBox_hr {
  opacity: 10%;
}

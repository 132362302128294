.contact-card {
  background-color: white !important;
  border: none;
  box-shadow: 1px 0px 10px hsl(0deg 0% 49.61% / 15%);
  padding: 2% 3%;
}

#form_Contact {
  margin-left: 25%;
}

.Msend {
  background-color: black;
  color: white;
}

.Msend:hover {
  background-color: black;
  color: white;
}

.subpara {
  font-weight: 100;
}

.tPolicy {
  color: #aa9c68;
}

.tPolicy:hover {
  color: #aa9c68;
}

.forum-btn {
	font-weight: 700;
	line-height: 21px;
	color: var(--title);
	border-radius: 25px;
	border-radius: 25px;
	border: 2px solid var(--title);
	padding: 10px 25px;
	display: inline-block;
}
.related-download-list {
	margin: 0;
	padding: 0;
	margin-top: 20px;
	li {
		list-style: none;
		&:not(:last-child) {
			margin-bottom: 15px;
		}
		a {
			background: var(--white);
			display: flex;
			.icon {
				width: 45px;
				height: 45px;
				display: flex;
				align-items: center;
				justify-content: center;
				background: var(--base);
				color: var(--white);
				svg {
					font-size: 16px;
					path {
						stroke: var(--white);
					}
				}
			}
			span {
				width: calc(100% - 45px);
				align-self: center;
				color: var(--title);
				font-size: 14px;
				text-transform: capitalize;
				padding-left: 15px;
			}
		}
	}
}
.video-container {
	position: fixed;
	inset: 0;
	z-index: 99999;
	display: flex;
	align-items: center;
	justify-content: center;
	> div {
		width: 100% !important;
		height: 450px !important;
		max-width: 720px;
		position: relative;
		z-index: 999;
	}
	.inner {
		background: linear-gradient(
			-90deg,
			#111d5e 0%,
			rgba(17, 29, 94, 0.769) 33.5%,
			rgba(17, 29, 94, 0.5) 100%
		);
		position: absolute;
		inset: 0;
		z-index: 99;
		cursor: zoom-out;
		.close {
			position: absolute;
			font-size: 30px;
			path {
				stroke: red;
			}
			right: 15px;
			top: 15px;
		}
	}
	animation: zoomInUp 0.3s;
	-webkit-animation: zoomInUp 0.3s;
	-moz-animation: zoomInUp 0.3s;
}
@keyframes zoomInUp {
	0% {
		opacity: 0.7;
	}
	100% {
		opacity: 1;
	}
}
.img-container {
	position: relative;
	img {
		width: 100%;
		max-height: 180px;
	}
	position: relative;
	.video--btn {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	cursor: pointer;
}
.video-info {
	padding: 0;
	margin: 0;
	padding-top: 15px;
	li {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		strong {
			font-weight: 600;
			color: var(--title);
		}
		border-bottom: 1px solid #e5e5e5;
		padding: 8px 0;
	}
}
.enroll-btn {
	height: 45px;
	padding: 0;
	font-size: 15px;
}
.education-forum-single-content {
	&-top {
		.title {
			max-width: 710px;
			margin-bottom: 20px;
		}
		img {
			width: 100%;
			max-height: 320px !important;
		}
	}
	.subtitle {
		font-weight: 600;
		margin-bottom: 15px;
	}
	p {
		max-width: 870px;
		&:not(:last-child) {
			margin-bottom: 26px;
		}
	}
}
.education-forum-author {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	color: var(--title);
	padding-top: 10px;
	margin-bottom: 25px;
	.author {
		display: flex;
		align-items: center;
		img {
			width: 60px;
			aspect-ratio: 1;
			object-fit: cover;
			border-radius: 50%;
			margin-right: 15px;
		}
		margin-right: 25px;
		margin-top: 15px;
	}
	.date {
		display: flex;
		margin-top: 15px;
		.icon {
			margin-right: 5px;
		}
	}
	@media (max-width: 1199px) {
		.author {
			img {
				width: 45px;
			}
		}
	}
}
.education-forum-tab {
	box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
	border-radius: 4px;
	border: none;
	margin-bottom: 35px;
	flex-wrap: nowrap;
	li {
		cursor: pointer;
		flex-grow: 1;
		text-align: center;
		padding: 12px 10px;
		transition: all ease 0.3s;
		color: var(--title);
		&:first-child {
			border-radius: 4px 0 0 4px;
		}
		&:last-child {
			border-radius: 0 4px 4px 0;
		}
		&.active {
			background: var(--base);
			color: var(--white);
		}
	}
	@media (max-width: 767px) {
		flex-wrap: wrap;
		margin: -5px;
		box-shadow: none;
		margin-bottom: 30px;
		li {
			box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
			margin: 5px;
			border-radius: 4px;
			&.active {
				box-shadow: none;
			}
		}
	}
}

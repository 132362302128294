.privacy-section {
  p {
    margin: 0;
    line-height: 1.6;
    font-weight: 500;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  .subtitle {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  ul {
    li {
      font-weight: 500;

      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
  }
}
.terms-section {
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;

    li {
      display: table;
      font-weight: 500;
      counter-increment: item;
      margin-bottom: 0.6em;

      &::before {
        content: counters(item, '.') '. ';
        display: table-cell;
        padding-right: 0.6em;
        font-size: 18px;
        color: #111d5e;
        font-weight: 500;
      }

      p {
        font-weight: 500;
        margin: 10px 0 !important;
      }

      ul {
        list-style-type: disc !important;
        counter-reset: item;
        margin: 0;
        padding: 0;
        li {
          &::before {
            content: '•';
          }
        }
      }

      ol {
        li {
          margin: 0;
          margin-top: 5px;

          &::before {
            content: counters(item, '.') ' ';
          }

          ul {
            list-style-type: disc !important;
            margin: 0;
            padding: 0;
            li {
              &::before {
                content: '•';
              }
            }
          }

          p {
            margin: 10px 0 !important;
          }
        }
      }
    }
  }
}
